import React from 'react'
import PageHeading from '../../components/page/pageHeading'
import { Link } from 'gatsby'

const CategoryPage = () => {

    const categoryName = '最新區塊鏈新聞'
    return (
        <>
            {/* Page header */}
            

            <div className='bg-black'>
                <div className='flex flex-col container-narrow h-72'>
                    <div className='text-4xl capitalize font-medium text-white flex-1 flex items-center'>
                        { categoryName }
                    </div>
                    <div className='flex-1'> {/* Spacer */} </div> 
                </div>
            </div>

            <div className='container-narrow grid grid-cols-6 gap-6 mb-12 -mt-36'>
            {/* <div className='container grid grid-cols-3 gap-6 mb-12'> */}
                <div className="col-span-4">
                    <div className="relative rounded-lg overflow-hidden h-full mb-6 h-[450px]">
                        <div className="absolute inset-0">
                            <img
                            src="https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/GECLX6CAC5F4ZMCQRGBWISN4XU.jpeg"
                            alt=""
                            className="w-full h-full object-center object-cover"
                            />
                        </div>
                        <div className="relative bg-gray-900 bg-opacity-50 p-6 h-full">
                            <div className="absolute bottom-6 max-w-3xl mx-auto flex flex-col">
                                <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
                                    <span className="block sm:inline">在混亂中構建: </span>
                                    <span className="block sm:inline">開發人員在 ETHDenver 都在討論的內容</span>
                                </h2>
                                <p className="mt-3 text-lg text-white">
                                    高中生、Web 2 轉換者和加密貨幣創始人花了一周時間在一個擁擠的場所構建金融的未來。 接下來是什麼？
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Two blocks */}
                    <div className="flex flex-row space-x-4">
                        <div className="relative rounded-lg overflow-hidden flex-1 h-96">
                            <div className="absolute inset-0">
                                <img
                                src="https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/GECLX6CAC5F4ZMCQRGBWISN4XU.jpeg"
                                alt=""
                                className="w-full h-full object-center object-cover"
                                />
                            </div>
                            <div className="relative bg-gray-900 bg-opacity-50 p-6 h-full">
                                <div className="absolute bottom-6 max-w-3xl mx-auto flex flex-col">
                                    <h2 className="text-lg font-extrabold tracking-tight text-white">
                                        <span className="block sm:inline">在混亂中構建: </span>
                                        <span className="block sm:inline">開發人員在 ETHDenver 都在討論的內容</span>
                                    </h2>
                                    <p className="mt-3 text-lg text-white">
                                        高中生、Web 2 轉換者和加密貨幣創始人花了一周時間在一個擁擠的場所構建金融的未來。 接下來是什麼？
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="relative rounded-lg overflow-hidden flex-1 h-96">
                            <div className="absolute inset-0">
                                <img
                                src="https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/GECLX6CAC5F4ZMCQRGBWISN4XU.jpeg"
                                alt=""
                                className="w-full h-full object-center object-cover"
                                />
                            </div>
                            <div className="relative bg-gray-900 bg-opacity-50 p-6 h-full">
                                <div className="absolute bottom-6 max-w-3xl mx-auto flex flex-col">
                                    <h2 className="text-lg font-extrabold tracking-tight text-white">
                                        <span className="block sm:inline">在混亂中構建: </span>
                                        <span className="block sm:inline">開發人員在 ETHDenver 都在討論的內容</span>
                                    </h2>
                                    <p className="mt-3 text-white">
                                        高中生、Web 2 轉換者和加密貨幣創始人花了一周時間在一個擁擠的場所構建金融的未來。 接下來是什麼？
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row space-x-4 mt-12">
                        <div className='flex-1 flex flex-col space-y-6'>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                        </div>
                        <div className='flex-1 flex flex-col space-y-6'>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                            <div>
                                <dt className="text-sm mb-1">17:21 • Mar 06 • Gogoblock</dt>
                                <dl className="text-lg">Drake Loses $275,000 Bitcoin Bet</dl>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='text-white mt-6 col-span-2'>
                    <div> {/* Subpage action */}
                        <h3 className='text-xl font-bold'>Like Blockchain?</h3>
                        <div className='text-skin-muted'>Add Blockchain to your interest</div>
                        <button className='rounded-full bg-primary p-3 text-xs'>Add Blockchain</button>
                    </div>
                    <div className='mt-12 text-black flex flex-col space-y-6'>
                        <h3 className='text-xl font-bold text-skin-primary'>POPULAR ON BLOCKCHAIN</h3>
                        <div className='flex flex-row space-x-6'>
                            <div className='text-2xl text-skin-primary font-bold'>1</div>
                            <div>Non equidem invideo, miror magis posuere velit aliquet.</div>
                        </div>
                        <div className='flex flex-row space-x-6'>
                            <div className='text-2xl text-skin-primary font-bold'>2</div>
                            <div>Non equidem invideo, miror magis posuere velit aliquet.</div>
                        </div>
                        <div className='flex flex-row space-x-6'>
                            <div className='text-2xl text-skin-primary font-bold'>3</div>
                            <div>Non equidem invideo, miror magis posuere velit aliquet.</div>
                        </div>
                        <div className='flex flex-row space-x-6'>
                            <div className='text-2xl text-skin-primary font-bold'>4</div>
                            <div>Non equidem invideo, miror magis posuere velit aliquet.</div>
                        </div>
                        <div className='flex flex-row space-x-6'>
                            <div className='text-2xl text-skin-primary font-bold'>5</div>
                            <div>Non equidem invideo, miror magis posuere velit aliquet.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryPage